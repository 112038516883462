import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 10px 20px;
  margin: 40px 0;

  h1 {
    font-weight: 100;
    font-size: 30px;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
  }
`;

const Contact = () => {
  return (
    <Container>
      <h1>Contact us today for a free consultation.</h1>
      <h2>Angelo Baldacchino</h2>
      <p>Principle</p>
      <p>
        Anviro Contractors
        <br />
        660 Jackson Valley Road
        <br />
        Oxford, NJ 07863
      </p>
      <p>
        <a href={`tel:1-908-689-2220`}>908-689-2220</a>
        <br />
        fax: 908-689-2154
        <br />
        <a href="mailto:anviro@comcast.net">anviro@comcast.net</a>
      </p>
      <p>References available upon request.</p>
    </Container>
  );
};

export default Contact;
