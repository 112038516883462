import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';
import Nav from '../Nav';

import Home from '../Home';
import Contact from '../Contact';

import Footer from '../Footer';

const Main = styled.main`
  background: #eee;
`;

const Header = styled.header`
  @media only screen and (max-width: 685px) {
    margin-top: 100px;
  }
`;

const Section = styled.section`
  margin: 0 auto;
  max-width: 1280px;
  padding: 1px 0;

  @media only screen and (max-width: 1360px) {
    margin: 0 40px;
  }
`;

const App = () => {
  return (
    <Router>
      <Main>
        <Header>
          <Nav />
        </Header>
        <Section>
          <Route path={`/`} exact component={Home} />
          <Route path={`/contact`} exact component={Contact} />
        </Section>
      </Main>
      <Footer>hello</Footer>
    </Router>
  );
};

export default App;
