import React from 'react';
import styled from 'styled-components';

import Card from './Card';
import WideCard from './WideCard';

import QualityImage from './images/quality.png';
import CraftmanshipImage from './images/craftmanship.png';
import ExperienceImage from './images/experience.png';
import ResidentialImage from './images/residential.jpg';
import CommercialImage from './images/commercial.jpg';

const qualityDescription = `
  With a commitment to quality and pride in a job well done, Anviro
  Contractors has established itself as a builder of choice. We are a
  proven resource in North West New Jersey with more than 20 years
  experience, covering all aspects of residential and commercial
  construction.
`;

const craftsmanshipDescription = `
  Quality also applies to our building materials- only artisan-grade
  products can ensure a long lasting result. Review our site and
  contact us today to schedule a free consultation.
`;

const experienceDescription = `
  We use our experience to address issues before they arise, ensuring
  a timely completion date with no call-backs. We partner with our
  clients from planning to completion, providing a professional
  detailed approach that has served our clients well and is the basis
  for our referrals and repeat business.
`;

const residentialDescription = `
  From conceptual design to finished landscaping, we employ only
  skilled tradesmen and use only high-quality materials. Close
  attention to detail is how we ensure satisfaction and is why we
  receive no call-backs after a job is done.
`;

const commercialDescription = `
  Engineers, architects, property managers and townships rely on us
  to complete difficult projects on time. We have a reputation for
  taking complicated jobs that other contractors can't accomplish.
  We have the knowledge and experience to complete difficult jobs
  and often collaborate with seasoned engineers and architects to
  deliver creative solutions to unique problems.
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  color: #333;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0px;
`;

const CardContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 990px) {
    display: block;

    article {
      margin-right: 0;
    }
  }
`;

const Home = () => {
  return (
    <>
      <Title>Quality and Trust - Since 1984</Title>
      <CardContainer>
        <Card
          title={`Quality`}
          image={QualityImage}
          description={qualityDescription}
        />
        <Card
          title={`Craftsmanship`}
          image={CraftmanshipImage}
          description={craftsmanshipDescription}
        />
        <Card
          title={`Experience`}
          image={ExperienceImage}
          description={experienceDescription}
        />
      </CardContainer>
      <WideCard
        title={`General Contracting Residential`}
        description={residentialDescription}
        items={[
          `General Contracting`,
          `Sidewalks & Patios`,
          `Steps & Foundations`,
          `Retaining Walls`,
          `Fireplaces`,
          `Special Projects`,
        ]}
        image={ResidentialImage}
      />
      <WideCard
        title={`General Contracting Commercial`}
        description={commercialDescription}
        items={[
          `General Contracting`,
          `Masonry`,
          `Drainage`,
          `Blacktop`,
          `Pool Restoration`,
          `Retaining Walls`,
          `Special Projects`,
        ]}
        image={CommercialImage}
      />
    </>
  );
};

export default Home;
