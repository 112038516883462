import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  background: red;
  padding: 20px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 685px) {
    display: none;
  }

  li {
    padding-right: 20px;
  }

  a {
    text-decoration: none;
    color: yellow;

    &:hover,
    &:active {
      color: white;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  color: yellow;
`;

const Button = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: yellow;
`;

const Copyright = styled.span`
  border-right: 1px solid black;
  padding-right: 10px;
  margin-right: 10px;
`;

const Footer = () => {
  const goBackToTop = () => {
    window.document.body.scrollTop = 0; // For Safari
    window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <Container>
      <Left>
        <Copyright>&copy; Anviro Contractors 2019</Copyright>
        <List>
          <li>
            <Link to={`/`}>Home</Link>
          </li>
          {/* <li>
            <Link to={`/`}>Residential</Link>
          </li>
          <li>
            <Link to={`/`}>Commercial</Link>
          </li> */}
          <li>
            <Link to={`/contact`}>Contact</Link>
          </li>
        </List>
      </Left>
      <Button onClick={goBackToTop}>Back to Top</Button>
    </Container>
  );
};

export default Footer;
