import React from 'react';
import styled from 'styled-components';

import LogoImage from './images/logo.png';

const Container = styled.div`
  display: flex;
`;

const Logo = () => {
  return (
    <Container>
      <img alt={`Anviro`} src={LogoImage} />
    </Container>
  );
};

export default Logo;
