import React from 'react';
import styled from 'styled-components';

const Container = styled.article`
  display: flex;
  margin-bottom: 40px;
  background: white;
  padding: 20px;

  div:first-child {
    margin-right: 40px;
    flex: 0 1 auto; /* for IE10 */
  }

  h2 {
    margin: 0;
    padding: 0;
    font-size: 35px;
    font-weight: 600;
  }

  p {
    flex: 0 1 auto; /* for IE10 */
  }

  @media only screen and (max-width: 990px) {
    display: block;

    img {
      max-width: 100%;
    }

    div:first-child {
      margin-right: 0;
    }

    & > div:last-child {
      text-align: center;
    }
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  ul {
    padding-left: 20px;

    &:first-child {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 990px) {
    display: block;
    ul {
      margin: 0;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
`;

const WideCard = ({ title, description, items, image }) => {
  const splitIndex = Math.ceil(items.length / 2);
  const items1 = items.slice(0, splitIndex);
  const items2 = items.slice(splitIndex, items.length);

  return (
    <Container>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <ItemsContainer>
          <ul>
            {items1.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          <ul>
            {items2.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </ItemsContainer>
      </div>
      <div>
        <img alt={title} src={image} />
      </div>
    </Container>
  );
};

export default WideCard;
