import React from 'react';
import styled from 'styled-components';
import HamburgerStyles from './styles';

const Button = styled.button`
  outline: none;

  .hamburger-inner {
    &,
    &:before,
    &:after {
      background-color: white;
    }
  }
`;

const Hamburger = ({ isActive = false, onClick } = {}) => {
  const isActiveClass = isActive ? `is-active` : ``;
  const buttonClass = `hamburger hamburger--slider ${isActiveClass}`;

  return (
    <>
      <HamburgerStyles />
      <Button onClick={onClick} className={buttonClass} type={`button`}>
        <span className={`hamburger-box`}>
          <span className={`hamburger-inner`} />
        </span>
      </Button>
    </>
  );
};

export default Hamburger;
