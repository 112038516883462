import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from './Logo';
import Hamburger from './Hamburger';

const navPadding = 30;

const HamburgerContanier = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;

  @media only screen and (max-width: 685px) {
    display: block;
  }
`;

const NavContainer = styled.nav`
  background: #ed353a;
  display: flex;

  @media only screen and (max-width: 685px) {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

const StretchLogo = styled.div`
  padding-left: ${navPadding - 10}px;
  flex: 1;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 685px) {
    display: block;
    overflow: hidden;
    transition: padding-bottom 0.8s, max-height 0.4s;
    max-height: ${({ isActive }) => (isActive ? `200` : `0`)}px;
    padding-bottom: ${({ isActive }) => (isActive ? `20px` : `0`)};
    padding-left: ${navPadding}px;

    li {
      padding: 10px 0;
    }
  }

  li {
    padding-right: ${navPadding}px;
  }

  a {
    /* display: none; */
    text-decoration: none;
    color: yellow;
    font-size: 20px;

    &:hover,
    &:active {
      color: white;
    }
  }
`;

const Nav = () => {
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);

  const onHamburgerClick = () => {
    setIsHamburgerActive(!isHamburgerActive);
  };

  return (
    <NavContainer>
      <HamburgerContanier>
        <Hamburger isActive={isHamburgerActive} onClick={onHamburgerClick} />
      </HamburgerContanier>
      <StretchLogo>
        <Link to={`/`}>
          <Logo />
        </Link>
      </StretchLogo>
      <List isActive={isHamburgerActive}>
        <li>
          <Link to={`/`}>Home</Link>
        </li>
        {/*
        <li>
          <Link to={`/`}>Residential</Link>
        </li>
        <li>
          <Link to={`/`}>Commercial</Link>
        </li> */}
        <li>
          <Link to={`/contact`}>Contact</Link>
        </li>
      </List>
    </NavContainer>
  );
};

export default Nav;
