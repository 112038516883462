import React from 'react';
import styled from 'styled-components';

const Container = styled.article`
  margin-right: 40px;
  margin-bottom: 40px;
  text-align: center;
  flex: 1;
  padding: 20px;
  background: white;

  &:last-child {
    margin-right: 0;
  }

  h2 {
    font-size: 35px;
    font-weight: 600;
  }

  p {
    text-align: left;
  }
`;

const Card = ({ image, title, description }) => {
  return (
    <Container>
      <div>
        <img alt={title} src={image} />
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
    </Container>
  );
};

export default Card;
